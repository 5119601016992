/**
 Custom JS Goes Here.
 */

function elementExists(selector) {
    return ($(selector).length);
}


$(document).ready(function () {

    let internal = null;

    // DA - To allow us to quickly set cookies without the need of PHP
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    $('a').on('click touch', function () {
        internal = true;
    });

    // DA - On document unload
    window.onbeforeunload = function (event) {
        if (!internal) {
            setCookie("notification", "", -1);
        }
    };

    // DA - Custom Alerts
    var js_alerts = $('#close-alert');
    var read_more_alert = $('#alert-read-more');

    js_alerts.on('click touch', function () {
        var cookieName = $(this).data('close');
        if (cookieName !== null) {
            setCookie($(this).data('close'), true, 1);
            $('#' + cookieName).fadeOut();
        }
    });

    read_more_alert.on('click touch', function () {
        var cookieName = $(this).data('close');
        if (cookieName !== null) {
            setCookie($(this).data('close'), true, 1);
            $('#' + cookieName).fadeOut();
        }
    });

    // Activate the custom drop down for the date of birth picker.
    $('.dob-picker').dateDropdowns({
        dropdownClass: 'custom-select',
        minAge: 14,
    });

    // Add custom attributes to elements.
    $('.day').attr("title", "Day");
    $('.month').attr("title", "Month");
    $('.year').attr("title", "Year");

    // Load in the lazy load images once in viewport
    $('.lazy').Lazy();
    $('.lazy-hidden').Lazy();

    $("#homepage-video").show();
    var homepageVideoToggle = $("#homepage-video-toggle");
    var homepageVideoToggleButton = $('#homepage-video-toggle');

    homepageVideoToggleButton.on("click", () => {
        var homepageVideo = $("#homepage-video");
        var homepageVideoElement = homepageVideo.get(0);
        var homepageVideoToggleButtonSpan =
            homepageVideoToggleButton.find("span");

        if (homepageVideoElement.paused) {
            homepageVideoElement.play();
            homepageVideoToggleButton.attr("aria-label", "Pause Video");
            homepageVideoToggleButtonSpan.attr("class", "fa fa-pause");

            return;
        }

        if (!homepageVideoElement.paused) {
            homepageVideoElement.pause();
            homepageVideoToggleButton.attr("aria-label", "Play Video");
            homepageVideoToggleButtonSpan.attr("class", "fa fa-play");

            return;
        }
    });

    // Accessibility options for the navigation
    $('.nav-link').on("keydown", function (event) {
        if (event.keyCode === 27) {
            $('.nav-link').blur();
            $('.subnav').each(function () {
                $(this).removeClass('subnav-display');
            })
        }
    });

    $(document).on("keydown", function (event) {
        if (event.keyCode === 9) {
            $('.subnav').each(function () {
                if ($(this).find(':focus').length !== 0) {
                    $(this).addClass('subnav-display');
                } else {
                    $(this).removeClass('subnav-display');
                }
            });
        }
    });

    $('.subnav a').on("focus", function () {
        $('.subnav').each(function () {
            if ($(this).find(':focus').length !== 0) {
                $(this).addClass('subnav-display');
            } else {
                $(this).removeClass('subnav-display');
            }
        });
    });


    $('.multi-select').multiselect();

    $('.cc-window').attr("tabindex", "0");
    $('.cc-message').attr("tabindex", "0");

    if (elementExists('.career-slider')) {

        const careerSliders = $('.career-slider');

        for (var i = 0; i < careerSliders.length; i++) {
            const question = careerSliders[i].dataset.question;

            noUiSlider.create(careerSliders[i], {
                start: [50],
                range: {
                    'min': [0],
                    'max': [100]
                }
            });
            careerSliders[i].noUiSlider.on('update', function (values, handle) {
                $('input[type="hidden"][name="' + question + '"]').val(Math.round(values[handle]));

            });
        }
    }

    (function () {
        let form = $('#apprenticeship-form');
        let filters = $('.apprenticeship_filters');

        filters.on('change', function () {
            form.submit();
        });
    })();

    //nav JS
    $('.mobile-nav').find('.collapse-control').on('click', function (e) {
        e.preventDefault();
        let parentElement = $(this).closest('.dropdown-link');
        let otherNavItems = $('.toplevel-menu-item').not(this).not($(this).closest('.toplevel-menu-item'));

        otherNavItems.find('.active').removeClass('active');
        otherNavItems.find('i.collapse-control').removeClass('fa-angle-up').addClass('fa-angle-down');

        $(this).toggleClass('fa-angle-down fa-angle-up');

        let subItems = parentElement.find('.dropdown-links');

        subItems.toggleClass('active');

        $.each(subItems, function () {
            let subItem = $(this);
            subItem.find('i.collapse-control').removeClass('fa-angle-up').addClass('fa-angle-down');
            if (subItem.hasClass('active')) {
                subItem.find('.dropdown-links').removeClass('active');
            }
        });
    });

    $('.accordion').on('show.bs.collapse', function () {
        $('.accordion').find('.collapse.in').collapse('hide');
    });

    $('.panel-collapse').on('shown.bs.collapse', function (e) {
        var $panel = $(this).closest('.panel');
        $('html,body').animate({
            scrollTop: $panel.offset().top + (-195)
        }, 500);
    });

    $('.accordion-nav').find('.collapse-control').on('click', function (e) {
        e.preventDefault();

        let parentElement = $(this).closest('.dropdown-link');
        let otherNavItems = $('.toplevel-menu-item').not(this).not($(this).closest('.toplevel-menu-item'));

        otherNavItems.find('.active').removeClass('active');
        otherNavItems.find('i.collapse-control').removeClass('open');

        $(this).toggleClass('open');

        let subItems = parentElement.find('.dropdown-links');

        subItems.toggleClass('active');

        $.each(subItems, function () {
            let subItem = $(this);
            subItem.find('i').removeClass('open');
            if (subItem.hasClass('active')) {
                subItem.find('.dropdown-links').removeClass('active');
            }
        });
    });

    $('.prevent-default-accordion').on('click', function (e) {
        e.preventDefault();
    });

    $('.contact-nav').find('.prevent-default-accordion').on('click', function (e) {
        e.preventDefault();

        let parentElement = $(this).closest('.dropdown-link');
        let otherNavItems = $('.toplevel-menu-item').not(this).not($(this).closest('.toplevel-menu-item'));

        otherNavItems.find('.active').removeClass('active');
        otherNavItems.find('i.collapse-control').removeClass('open');

        $(this).find('i').toggleClass('open');

        let subItems = parentElement.find('.dropdown-links');

        subItems.toggleClass('active');

        $.each(subItems, function () {
            let subItem = $(this);
            subItem.find('i').removeClass('open');
            if (subItem.hasClass('active')) {
                subItem.find('.dropdown-links').removeClass('active');
            }
        });
    });

    // Sticky nav
    let scrollPosOld = window.pageYOffset;

    $(window).scroll(function () {
        let scrollPos = window.pageYOffset,
            nav = $('.nav-header'),
            navHeight = nav.outerHeight(),
            scrollingClass = 'scrolling',
            detachedClass = 'nav-detached';

        if (elementExists('.fixed-apply-bar')) {
            var element = $('.fixed-apply-bar');
        }

        if (scrollPos > (navHeight / 2)) {
            $('body').addClass('small-nav');
        } else {
            $('body').removeClass('small-nav');
        }

        // Scroll up
        if ((scrollPosOld > scrollPos)) {
            nav.removeClass(scrollingClass);
            $('body').addClass('scrolling-up');

            scrollPos > (navHeight * 2)
                // Nav detached from the top of the page
                ? nav.addClass(detachedClass)
                // Nav at top of the page
                : nav.removeClass(detachedClass);

            if (elementExists('.fixed-apply-bar')) {
                element.addClass('with-nav-bar-on-mobile');
            }
        }
        // Scroll down
        else if (scrollPos > navHeight) {
            nav.addClass(scrollingClass);
            $('body').removeClass('scrolling-up');

            if (elementExists('.fixed-apply-bar')) {
                element.removeClass('with-nav-bar-on-mobile');
            }
        }

        // Assign the new scroll value
        scrollPosOld = scrollPos;

        // setParalaxEffect(Selector, speed, offset, direction);
        if (elementExists('.header-graphic h1')) {
            var paralax = $('.header-graphic h1');

            if (!paralax.hasClass('static-text')) {
                setParalaxEffect(paralax, 0.6, 50, ('up'));
            }
        }

        if (elementExists('.header-graphic .bg')) {
            setParalaxEffect($('.header-graphic .bg'), 0.8, 0, ('down'));
        }

        $('.parallax').each(function () {

            if (($(this).find('.parallax-image-1')).length) {
                setParalaxEffect($(this).find('.parallax-image-1'), 0.4, 400, ('down'));
            }
            if (($(this).find('.parallax-image-2')).length) {
                setParalaxEffect($(this).find('.parallax-image-2'), 0.5, 400, ('up'));
            }
            if (($(this).find('.parallax-image-3')).length) {
                setParalaxEffect($(this).find('.parallax-image-3'), 0.3, 400, ('up'));
            }

        });
    });

    // Scroll styles for fixed apply bar on course detail pages
    if (elementExists('.fixed-apply-bar')) {
        let scrollPosOld = window.pageYOffset;

        $(window).scroll(function () {

            var element = $('.fixed-apply-bar'),
                scrollPos = window.pageYOffset;

            // Offset from nav needed on desktop
            if (isMobile()) {
                var marker = $('.fixed-apply-bar-marker').offset().top;
            } else {
                var marker = $('.fixed-apply-bar-marker').offset().top - $('.nav-header').outerHeight();
            }

            if (scrollPos >= marker) {
                if (!element.hasClass('fixed')) {
                    element.addClass('fixed');
                    $('.fixed-apply-bar-marker').addClass('active');
                }
            } else {
                if (isMobile()) {
                    if (scrollPos <= marker - $('.nav-header').outerHeight()) {
                        element.removeClass('fixed');
                        $('.fixed-apply-bar-marker').removeClass('active');
                    }
                } else {
                    element.removeClass('fixed');
                    $('.fixed-apply-bar-marker').removeClass('active');
                }
            }

            scrollPosOld = scrollPos;
        });
    }

    if (elementExists('.intro-with-video')) {

        $('.intro-with-video').find('.overlay').on('click', function (e) {
            $(this).fadeOut(function () {
                let $iframe = $(this).parent().find('iframe');

                $iframe.attr('src', $iframe.data('autoplay-src'));
            });
        });
    }


    if (elementExists('.ms-options-wrap')) {
        let optionsList = $(".ms-options-wrap");
        $("button", optionsList).attr("aria-live", "polite");

        let el = $("ul li", optionsList);

        el.attr("tabindex", "0");

        el.on("keyup", function (event) {
            if (event.keyCode === 13) {
                $(this).find('input').click();
            }
        });

        let optionInput = $("ul li input", optionsList);

        optionInput.click(function () {
            if ($(this).parent().hasClass('selected')) {
                document.getElementById('multi-select-status').innerHTML = 'Unselected';

            } else {
                document.getElementById('multi-select-status').innerHTML = 'Selected';
            }
        });
    }

    if (elementExists('.main-off-canvas-nav')) {
        $('.main-off-canvas-nav').JOffCanvasNav({
            'menuLink': '.navbar-toggler'
        });
    }

    if (elementExists('.grid-slider')) {
        $('.grid-slider').slick({
            lazyLoad: 'ondemand',
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: true,
        });
    }

    if (elementExists('.gallery-slider')) {
        $('.gallery-slider').slick({
            lazyLoad: 'progressive',
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            swipe: true,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true

                    }
                }
            ]
        });
    }

    if (elementExists('.campus-intro-slider')) {
        var $slider = $('.campus-intro-slider').slick({
            lazyLoad: 'progressive',
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        });

        $slider.slickLightbox({
            itemSelector: 'a',
            slick: {
                nextArrow: '<span class="lightbox-arrow--left lightbox-arrow btn relative-icon"><i class="icon-arrow-bold-left"></i></span>',
                prevArrow: '<span class="lightbox-arrow--right lightbox-arrow btn relative-icon"><i class="icon-arrow-bold-right"></i></span>',
            }
        });
    }

    if (elementExists('#ac-search')) {

        const input = $('#ac-search');
        const searchOptions = input.data();

        let options = {
            url: function (phrase) {
                return searchOptions.searchUrl + '?q=' + phrase;
            },

            adjustWidth: false,

            getValue: searchOptions.searchValue,

            ajaxSettings: {
                dataType: "json"
            },
            minCharNumber: 3,
            requestDelay: 200,

            list: {
                maxNumberOfElements: 6,

                onChooseEvent: function () {
                    var slug = input.getSelectedItemData().slug;

                    window.location.href = searchOptions.resultUrl + slug
                },

                match: {
                    enabled: false
                },

            }
        };

        input.easyAutocomplete(options);

        // easy autocomplete removes JS (input group) and replaces with easy-autocomplete class, this will rebuild the
        // dom for any where the easyautocomplete plugin is called.

        $('.easy-autocomplete').closest('.input-group').each(function (i, inputGroup) {
            $(inputGroup).removeClass('input-group');
            $autocomplete = $(inputGroup).find('.easy-autocomplete');
            $(inputGroup).find('.input-group-addon').prependTo($autocomplete);
            $autocomplete.addClass('input-group');
        });
    }

    if (elementExists('.hidden-content-section')) {

        $('.hidden-content-section').find('.toggle-hidden-content').on('click', function (e) {
            e.preventDefault();
            let $self = $(this);

            $(this).fadeOut(function () {
                $self.parents('.hidden-content-section').find('.hidden').removeClass('hidden');
            });
        });
    }

    if (elementExists('.two-slide-carousel')) {
            var newsSliderPauseButton = $("#news-slider-pause");
            var newsSliderPauseButtonSpan = newsSliderPauseButton.find("span");

            newsSliderPauseButton.on("click", function () {
                if (window.newsSliderPlaying) {
                    $(".two-slide-carousel .carousel").slick("slickPause");
                    newsSliderPauseButton.attr("aria-label", "Play Slider");
                    newsSliderPauseButtonSpan.attr("class", "fa fa-play");
                    window.newsSliderPlaying = false;

                    return;
                }

                if (!window.newsSliderPlaying) {
                    $(".two-slide-carousel .carousel").slick("slickPlay");
                    newsSliderPauseButton.attr("aria-label", "Pause Slider");
                    newsSliderPauseButtonSpan.attr("class", "fa fa-pause");
                    window.newsSliderPlaying = true;
                }
            });

            var eventsSliderPauseButton = $("#events-slider-pause");
            var eventsSliderPauseButtonSpan =
                eventsSliderPauseButton.find("span");

            eventsSliderPauseButton.on("click", function () {
                if (window.eventsSliderPlaying) {
                    $(".two-slide-carousel .carousel").slick("slickPause");
                    eventsSliderPauseButton.attr("aria-label", "Play Slider");
                    eventsSliderPauseButtonSpan.attr("class", "fa fa-play");
                    window.eventsSliderPlaying = false;

                    return;
                }

                if (!window.eventsSliderPlaying) {
                    $(".two-slide-carousel .carousel").slick("slickPlay");
                    eventsSliderPauseButton.attr("aria-label", "Pause Slider");
                    eventsSliderPauseButtonSpan.attr("class", "fa fa-pause");
                    window.eventsSliderPlaying = true;
                }
            });

        //expand on mobile
        setTwoSlideCarousels();

        $(window).resize(function () {
            setTwoSlideCarousels();
        });

    }

    //populateCalendar();
});

function setTwoSlideCarousels() {

    if ($(window).width() < 992) {

        if (!$('.two-slide-carousel .slick-initialized').length) {

            let $carousels = $(".two-slide-carousel");

            $carousels.each(function () {
                let $carousel = $(this).find('.carousel');

                $carousel.slick({
                    lazyLoad: 'ondemand',
                    draggable: false,
                    dots: true,
                    slidesToShow: 1,
                    nextArrow: $carousel.find('.slick-next'),
                    prevArrow: $carousel.find('.slick-prev'),
                    appendDots: $carousel.parent().find('.dots')
                });
            });
        }
    } else if ($('.two-slide-carousel .slick-initialized').length) {

        let $carousels = $(".two-slide-carousel");

        $carousels.each(function () {
            let $carousel = $(this).find('.carousel');

            $carousel.slick('unslick');
        });
    }

    $('.scrollable-content').each(function () {
        if (!isScrolledIntoView($(this))) {
            $(this).addClass('hidden');
        }
    });

    $(document).on('scroll', function () {
        $('.hidden').each(function () {
            if (isScrolledIntoView($(this))) {
                $(this).removeClass('hidden').css({ 'display': 'none' }).fadeIn();
            }
        });
    });
}

if (elementExists('.simple-gallery')) {
    $('.simple-gallery').slick({
        lazyLoad: 'ondemand',
        slidesToShow: 2,
        arrows: false,
        dots: true,
    }).slickLightbox({
        background: "rgba(0,0,0,0.5)",
        slick: {
            nextArrow: '<span class="lightbox-arrow--left lightbox-arrow btn relative-icon"><i class="icon-arrow-bold-left"></i></span>',
            prevArrow: '<span class="lightbox-arrow--right lightbox-arrow btn relative-icon"><i class="icon-arrow-bold-right"></i></span>'
        }
    });
}

if (elementExists('.facilities-slider')) {

    $('.facilities-slider').each(function () {
        var $slider = $(this);

        $slider.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 4,
            nextArrow: $('.slick-next-arrow'),
            prevArrow: $('.slick-prev-arrow'),
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            appendDots: $slider.parents('.facilities').find('.dots'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                    dots: true,
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        touchThreshold: 15,
                    },
                    dots: true,
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                    dots: true
                }
            ]
        });

        $slider.slickLightbox({
            itemSelector: 'a',
            slick: {
                nextArrow: '<span class="lightbox-arrow--left lightbox-arrow btn relative-icon"><i class="icon-arrow-bold-left"></i></span>',
                prevArrow: '<span class="lightbox-arrow--right lightbox-arrow btn relative-icon"><i class="icon-arrow-bold-right"></i></span>'
            }
        });
    });
}


if (elementExists('.campuses-slider')) {
    var campusSliderPauseButton = $("#our-campus-slider-pause");
    var campusSliderPauseButtonSpan = campusSliderPauseButton.find("span");

    campusSliderPauseButton.on("click", function () {
        if (window.campusSliderPlaying) {
            $(".campuses-slider").slick("slickPause");
            campusSliderPauseButton.attr("aria-label", "Play Slider");
            campusSliderPauseButtonSpan.attr("class", "fa fa-play");
            window.campusSliderPlaying = false;

            return;
        }

        if (!window.campusSliderPlaying) {
            $(".campuses-slider").slick("slickPlay");
            campusSliderPauseButton.attr("aria-label", "Pause Slider");
            campusSliderPauseButtonSpan.attr("class", "fa fa-pause");
            window.campusSliderPlaying = true;
        }
    });

    $('.campuses-slider').each(function () {
        var $slider = $(this);

        $slider.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 4,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            appendDots: $slider.parents('.campuses').find('.dots'),
            prevArrow: false,
            nextArrow: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                    dots: true,
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        touchThreshold: 15,
                    },
                    dots: true,
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                    dots: true
                }
            ]
        });
    });
}

if (elementExists('.staff-slider')) {
    $('.staff-slider').slick({
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $('.staff-slider').on('setPosition', function () {
        $(this).find('.slick-slide').height('auto');
        var slickTrack = $(this).find('.slick-track');
        var slickTrackHeight = $(slickTrack).height();
        $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
    });
}

if (elementExists('.subject-detail-slider')) {
    $('.subject-detail-slider').slick({

        lazyLoad: 'ondemand',
        slidesToShow: 4,
        nextArrow: '<i class="icon-arrow-bold-right next-arrow"></i>',
        prevArrow: '<i class="icon-arrow-bold-left previous-arrow"></i>',
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 574,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                }
            }
        ]
    });
}

function initMap() {
    if (elementExists($('#map'))) {
        var mapsJSON;

        $.getJSON("/themes/site/assets/javascript/map-config.json", function (json) {
            mapsJSON = json;

            let $map = $('#map');

            var styledMapType = new google.maps.StyledMapType(mapsJSON);

            let map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: $map.data('lat'), lng: $map.data('lng') },
                zoom: 15,
                disableDefaultUI: true,
                fullscreenControl: true
            });

            let marker = new google.maps.Marker({
                position: { lat: $map.data('lat'), lng: $map.data('lng') },
            });

            marker.setMap(map);
            map.mapTypes.set('styled_map', styledMapType);
            map.setMapTypeId('styled_map');
        });

    }
}

//infinite scroll

function isScrolledIntoView(elem) {
    let docViewTop = $(window).scrollTop(),
        docViewBottom = docViewTop + $(window).height(),
        elemTop = $(elem).offset().top,
        elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

function setParalaxEffect($el, speed, offset, direction) {

    var elementStart = $el.offset().top - (offset || 0),
        elementEnd = $el.offset().top + $el.innerHeight(),
        scrollPosition = $(window).scrollTop();

    if (scrollPosition > elementStart && scrollPosition < elementEnd) {
        var percentage = (scrollPosition - $el.offset().top) / $el.innerHeight() * 100;
        if (direction === 'up') {
            $el.css("transform", "translateY(" + ((percentage * speed) * -1) + "px)");
        } else {
            $el.css("transform", "translateY(" + (percentage * speed) + "px)");
        }
    }
}

function populateCalendar() {
    let start;
    let end;

    if ($('#dateFrom').val() && $('#dateTo').val()) {
        start = moment($('#dateFrom').val(), "YYYY-MM-DD");
        end = moment($('#dateTo').val(), "YYYY-MM-DD");
    } else {
        start = moment();
        end = moment().add(28, 'days');
    }

    var available = $("#available-dates").val();
    available = available.split(",");

    $('.date-range-picker').daterangepicker({
        "locale": {
            "autoUpdateInput": false,
            "format": "DD/MM/YYYY",
            "firstDay": 1,
        },
        "autoApply": true,
        "startDate": start,
        "minDate": moment(),
        "endDate": end,
        "opens": "left",
        "linkedCalendars": false,
        "applyClass": "btn-success date-picker date-picker--button date-picker--button-apply",
        "cancelClass": "btn-default date-picker date-picker--button",
        "isCustomDate": function (date) {
            if (available.indexOf(date.format('YYYY-MM-DD')) > -1) {
                return 'has-items';
            } else if (date > moment()) {
                return 'eligible';
            } else {
                return 'ineligible';
            }
        }
    });

    $('.date-range-picker').on('show.daterangepicker', function (ev, picker) {
        $('body').addClass('date-picker-visible');
    });

    $('.date-range-picker').on('hide.daterangepicker', function (ev, picker) {
        $('body').removeClass('date-picker-visible');
    });

    $('.date-range-picker').on('apply.daterangepicker', function (ev, picker) {
        var startDateOutput = picker.startDate.format('DD/MM/YYYY');
        var endDateOutput = picker.endDate.format('DD/MM/YYYY');
        $('#daterange-selection').html(startDateOutput + " - " + endDateOutput);
        $('#daterange-selection').attr('title', startDateOutput + " to " + endDateOutput);

        $('#dateFrom').val(picker.startDate.format('YYYY-MM-DD'));
        $('#dateTo').val(picker.endDate.format('YYYY-MM-DD'));

        // $('form').request('onFilterChange', {
        //     data: {
        //         'dateFrom': picker.startDate.format('YYYY-MM-DD'),
        //         'dateTo': picker.endDate.format('YYYY-MM-DD')
        //     },
        //     update: {resultcount: '#result'}
        // });
    });
}

// Add error class to div for error fields
$(window).on('ajaxInvalidField', function (event, fieldElement, fieldName, errorMsg, isFirst) {
    $(fieldElement).closest('div').addClass('error');
});


// county changer

$(function () {
    $('#country').on('change', function () {

        var county = $('#county');

        if ($(this).val() !== "GB") {
            county.val("Non UK County").attr('disabled', true);
        } else {
            county.val("Country").attr('disabled', false);
        }
    });
});

// $(function() {
//     //14 years ago..
//     var start = moment().subtract(5113, 'days');
//
//     $('input[name="dob"], input[name="date_of_birth"]').daterangepicker({
//         singleDatePicker: true,
//         maxDate: start,
//         startDate: start,
//         showDropdowns: true,
//         buttonClasses: 'btn btn-primary',
//         locale :{
//             format: 'DD/MM/YYYY'
//         }
//     }).val('');
// });

$(function () {
    $('.logos-slider').slick({

        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        mobileFirst: true,
        arrows: false,
        responsive: [{
            breakpoint: 578,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 4
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 6
            }
        }, {
            breakpoint: 991,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 8
            }
        }]
    });
});

// form hide/show
$(function () {
    $('.show-hide-form-select').on('change', function () {
        var element = $(this);
        var elementValue = element.find(":selected").text();
        var dataType = element.attr('data-alias');
        var dataPartner = $('[data-field-name=' + dataType + '_other]');

        if (elementExists(dataPartner)) {
            if (elementValue === "Other") {
                dataPartner.removeClass('d-none');
            } else {
                dataPartner.addClass('d-none').find('input').val('');
            }
        }

    });
});

$(function () {
    let $table = $('.wysiwyg table');
    $table.wrap('<div class="table-responsive">');
    $table.addClass('table');
});

$(function () {
    $('#event-form').on('click', function () {
        $('html,body').animate({
            scrollTop: $('#form').offset().top - 215
        }, 100, "linear", function () {
            setTimeout(function () {
            }, 150);
        });
    });
});

$(function () {
    // loop through each element and get selected value
    // disable these values in everything else which ISNT current element


    function changeDropDownValues(changeValue, elements) {
        elements.each(function () {
            var element = $(this);
            element.find("option[value='" + changeValue + "']").prop('disabled', true);
        });
    }

    // each time a change is made
    $('.a-level-choices').on('change', function () {

        var allElements = $('.a-level-choices');

        allElements.each(function () {
            $(this).find('option').prop("disabled", false);
        });
        allElements.each(function () {
            var currentValue = $(this).val();
            var otherElements = $('.a-level-choices').not($(this));

            changeDropDownValues(currentValue, otherElements);
        });
    });
});

function iOS() {

    var iDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ];

    if (!!navigator.platform) {
        while (iDevices.length) {
            if (navigator.platform === iDevices.pop()) {
                return true;
            }
        }
    }

    return false;
}

if (!("ontouchstart" in document.documentElement)) {
    document.documentElement.className += " no-touch";
}

if (iOS) {
    // $(document).ready(function(){
    //
    //     $('.course-area-card__link').on("click touchend", function(e) {
    //         var el = $(this);
    //         var link = el.attr("href");
    //         window.location = link;
    //     });
    // });
}

$(function () {
    if (elementExists('#_type')) {
        let typeSelect = document.querySelector('#_type'),
            hiddenFields = document.querySelector('#employer_fields');

        typeSelect.addEventListener('change', function () {
            if (typeSelect.value === 'Employer') {
                hiddenFields.classList.remove('d-none')
            } else {
                hiddenFields.classList.add('d-none');
            }
        });
    }
});

(function () {
    let current = 1;
    $('a[data-groups]').on('click', function () {
        const total = this.dataset.groups;
        current++;

        if (total === current) {
            $(this).hide();
        }

        $('.news-group-' + current).removeClass('d-none');
    });
})();

// Reset the reCAPTCHA when AJAX form errors
$(window).on('ajaxError', function (e) {
    if (elementExists('.g-recaptcha')) {
        if (typeof grecaptcha == 'object') {
            grecaptcha.reset();
        }
    }
});

function updatePage(form, context, data, textStatus, jqXHR) {

    var formElementID = $($(form)[0]).attr('id');
    var element = $($('.' + formElementID)[0]);

    element.closest('.inactive-style').removeClass('inactive-style');
    element.addClass('lighterPink-background pink-text');

}

$(function () {
    function goToByScroll(id) {

        let navHeight = 100;

        $('html,body').animate({
            scrollTop: $("#" + id).offset().top - navHeight
        },
            'fast');
    }

    $(document).on('change', '#personal_email', function () {
        $('#marketing').prop('checked', false);
    });

    $('#personal-details-form').on('ajaxSuccess', function () {
        goToByScroll($(this).attr('id'));
    });
});

window.campusSliderPlaying = true;
window.newsSliderPlaying = true;
window.eventsSliderPlaying = true;

const lastLink = $("#main-mobile a").last();
const closeButton = $("#navbar-toggler");

lastLink.on("keydown", (event) => {
    if (event.key === "Tab" && !event.shiftKey) {
        event.preventDefault();
        closeButton.focus();
    }
});
