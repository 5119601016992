;(function($){

    $.fn.extend({
        JOffCanvasNav: function(options) {

            let defaultOptions = {
                'menuLink': '.nav-toggle'
            };

            let plugin = this,
                $body = $('body');

            //closes the navigation
            plugin.close = function() {
                $body.removeClass('mobile-nav-open');
                $body.unbind('touchmove');

                $(this.settings.menuLink).removeClass('open');

                plugin.find('.mobile-nav.open').removeClass('open');
                plugin.reset();
            };

            plugin.open = function() {
                // $body.bind('touchmove', function(e){e.preventDefault()});
                plugin.find('.mobile-nav').addClass('open');
                $(this.settings.menuLink).addClass('open');
                plugin.find('.mobile-nav').attr("aria-hidden", "false")
            };

            //revert navigation into original state
            plugin.reset = function() {
                plugin.find('.open').removeClass('open');
                plugin.find('.mobile-nav').attr("aria-hidden", "true")
            };

            //initialise all events for plugin
            plugin.setEvents = function() {
                $(this).find('.underlay').on('click', function(e){
                    plugin.close();
                });

                $(this.settings.menuLink).on('click', function(e){
                    e.preventDefault();

                    $(this).toggleClass('open');
                    $body.toggleClass('mobile-nav-open');
                    $body.addClass('collapsing');

                    setTimeout(function(){
                        $body.removeClass('collapsing');
                    }, 500);

                    if($body.hasClass('mobile-nav-open')){
                        plugin.open();
                    }else{
                        plugin.close();
                    }
                });

                $(this).find('a').on('click', function(e){
                    if($(this).data('nav')){
                        e.preventDefault();

                        let $nav = $('#'+$(this).data('nav'));

                        plugin.find('.open').removeClass('open');
                        $nav.addClass('open');
                    }
                });
            };

            //initialise plugin
            plugin.init = function() {
                this.settings = $.extend({}, defaultOptions, options);
                plugin.setEvents();
            };

            plugin.init();

            return this;
        }
    });

})(jQuery);
